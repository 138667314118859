import { render, staticRenderFns } from "./MainTableFilters.vue?vue&type=template&id=66522661&scoped=true"
import script from "./MainTableFilters.vue?vue&type=script&lang=js"
export * from "./MainTableFilters.vue?vue&type=script&lang=js"
import style0 from "./MainTableFilters.vue?vue&type=style&index=0&id=66522661&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66522661",
  null
  
)

export default component.exports