<template>
  <div>
    <v-edit-dialog ref="dialog" @save="save" @open="open">
      <span>{{ getValue(item,column) }}</span>
      <template v-slot:input>
        <v-form @submit.prevent @keyup.native.enter="save()">
          <ComboBox
            v-model="selectedValue"
            :items="items"
            autofocus
          />
        </v-form>
        <v-row align="center" justify="space-around">
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn text @click="save()" color="primary" type="submit">Save</v-btn>
        </v-row>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
import ComboBox from "@/views/partials/form/ComboBox.vue";
import { GET_GROUPED_DETAILS } from "@/store/pricing.module";
export default {
  components: { ComboBox },
  props: ["value", "menuAction", "item", "column", "requestData"],
  data() {
    return {
      menu: false,
      items: [],
      selectedValue: "",
    };
  },
  methods: {
    save() {
      this.$emit("save", {
        item: this.item,
        column: this.column,
        value: this.selectedValue,
      });
      this.$refs.dialog.save();
    },
    open() {
      this.getRowDetails(this.item);
      this.selectedValue = this.item[this.column];
    },
    cancel() {
      this.$refs.dialog.cancel();
    },
    getValue(item,column){
        return item[column];
    },
    getRowDetails(item) {
      if (this.requestData.prepared && this.requestData.prepared[item.row_id]) {
        this.items = this.requestData.prepared[item.row_id].details
          .filter(
            (x) =>
              this.requestData.prepared[item.row_id].selected[x.checkbox_id] ===
              true
          )
          .map((x) => x[this.column]?x[this.column]:'');
          console.log( this.items);
        if(this.items.length === 0){
            this.items.push("");
        }
        return;
      }
      let params = item;
      params["data"] = {
        date_type: this.requestData.date_type,
        date: this.requestData.date,
        expire_date: this.requestData.expire_date,
        type_id: this.requestData.type_id,
        tag_id: this.requestData.tag_id,
        week: this.requestData.week,
      };
      this.$store.dispatch(GET_GROUPED_DETAILS, params).then((response) => {
        this.items = response.details.map((x) => x[this.column]?x[this.column]:'');
      });
    },
  },
};
</script>
