<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      ref="dialog"
      persistent
      max-width="98vw"
      @keydown.esc="hide"
      @keydown.enter="save"
      @close="hide"
      @save="save"
    >
      <v-card class="card-sticky">
        <v-card-title class="sticky-header">
          <span class="headline">Price Position</span>
          <v-spacer></v-spacer>
          <v-alert
            class="mt-3"
            dense
            border="left"
            type="success"
            icon="mdi-table"
          >
            Meat: <strong>{{ meat }}</strong> Description:
            <strong>{{ description }}</strong>
          </v-alert>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" fab dark small color="teal" @click="$emit('reloadData',params)">
            <v-icon dark> mdi-reload </v-icon>
          </v-btn>
          <v-select
            v-model="requestData.columns"
            label="Additional Columns"
            :items="columns"
            prepend-icon="mdi-tag"
            item-text="name"
            item-value="value"
            multiple
            @change="onChangeFilters(currentFilters)"
          ></v-select>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-alert dense border="left" type="info" icon="mdi-coin">
                * Client price :
                <strong
                  ><input
                    type="text"
                    v-model="price"
                    @change="customPrice = price"
                    class="price-input"
                /></strong>
              </v-alert>
            </v-col>
            <v-col cols="5">
              <v-alert dense border="left" type="warning" icon="mdi-coins">
                ^^ Weighted average price :
                <strong>{{ averagePrice }}</strong>
              </v-alert>
            </v-col>
            <v-col cols="2">
              <v-alert dense border="left" type="warning" icon="mdi-coins">
                Min price :
                <strong>{{ min }}</strong>
              </v-alert>
            </v-col>
            <v-col cols="2">
              <v-alert dense border="left" type="warning" icon="mdi-coins">
                Max price :
                <strong>{{ max }}</strong>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-layout column>
                <v-flex md6 style="overflow: auto">
                  <Table
                    :loading="isProcessing"
                    :headers="headers"
                    :items="preparedDetails"
                    :selected="setSelected"
                    :notSpecial="true"
                    :showCheckbox="true"
                    :height="'54vh'"
                    :hideFilters="true"
                    :getContent="getContentOnHover"
                    @changeEvent="changePrice"
                    @checkboxes="selectedItems($event.selectedChangeBoxes, $event.isInitial)"
                    @showOffer="showOffer"
                    :sort="'1'"
                    :tableName="detailsTableName"
                  >
                  <template v-slot:select="{ header, item }">
                    <v-tooltip v-if="header.value === 'average_price' && item.number === 1 && item.priceData && item.priceData.isConverted" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            class="set-cursor-pointer text-warning font-weight-bold"
                            v-bind="attrs"
                            v-on="on"
                            >
                                {{ item[header.value] }}
                            </span>
                        </template>
                        <span>
                            Converted from: {{ item.priceData.offerCurrency }}<br />
                            Exchange rate: {{ item.priceData.exchangeRate }}<br />
                            Unconverted price: {{ item.priceData.baseOfferPrice }}
                        </span>
                    </v-tooltip>
                    <v-tooltip v-else-if="header.value === 'average_price' && item.number === 1 && item.priceData && !item.priceData.offerCurrency" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            class="set-cursor-pointer text-danger font-weight-bold"
                            v-bind="attrs"
                            v-on="on"
                            >
                                {{ item[header.value] }}
                            </span>
                        </template>
                        <span>
                            Offer currency missing
                        </span>
                    </v-tooltip>
                    <template v-else>
                        {{ item[header.value] }}
                    </template>
                  </template>
                  </Table>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col cols="4">
              <Filters
                ref="filtersComponent"
                :data="details"
                :columns="columns"
                @filters="onChangeFilters"
                :filteredDetails="filteredDetails"
                :setFilters="filters"
                :detailsTableName="detailsTableName"
              ></Filters>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="sticky-footer">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="hide"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import _ from "lodash";
import Table from "@/views/partials/inlineEditTable/Table.vue";
import Filters from "./Filters.vue";
import TableService from "@/common/table.service";

export default {
  components: { Table, Filters },
  props: [
    "dialog",
    "details",
    "requestData",
    "columns",
    "clickedId",
    "params",
    "old",
    "config",
    "detailsTableName",
    "filtersValues"
  ],
  data() {
    return {
      isProcessing: false,
      headers: [],
      averagePrice: 0,
      min: 0,
      max: 0,
      price: 0,
      customPrice: 0,
      key: null,
      selected: [],
      usedDetails: [],
      filteredDetails: [],
      autocompleteKey: 0,
      currentFilters: {},
      setSelected: null,
      filters: {},
      hasOldSelectedData: false,
      meat: "",
      description: "",
      offerTableName:'VueTable_vendorOffersTable_preferences',
      defaultHeaders: [
        {
          text: "Number",
          value: "number",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
          hover: "showMinMax",
          click: "showOffer",
        },
        {
          text: "Vendor Price",
          value: "average_price",
          align: "center",
          slot: true,
          disabled: false,
          noFilterable: true,
        },
        {
          text: "Client Price",
          value: "client_price",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
        },
        {
          text: "Quantity",
          value: "total_kg",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
        },
      ],
      show: false,
      preparedDetails: []
    };
  },
  watch: {
    price: function (v) {
      this.price = v
        ? v
            .toString()
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1")
        : null;
    },
    details: {
      handler: function (details) {
        let old =
          this.requestData.prepared && this.requestData.prepared[this.clickedId]
            ? JSON.parse(
                JSON.stringify(this.requestData.prepared[this.clickedId])
              )
            : null;

        this.hasOldSelectedData = !!old;

        let filtersApplied = false;
        if (Object.values(this.filtersValues).length) {
          this.filters = {...this.filtersValues};

          for (const [key, filters] of Object.entries(this.filtersValues)) {
            filters.forEach(filter => {
              let isFilterValid = false;

              details.forEach(detail => {
                if (detail[key] === filter) {
                  isFilterValid = true;
                }
              });

              if (!isFilterValid) {
                this.filters[key] = this.filters[key].filter(f => f !== filter);
              }
            });
          }

          filtersApplied = true;
        }

        if (old) {
          if (!this.$route.params.clone_id) {
            this.price = old.info.client_price;
            this.customPrice = old.info.client_price;
            this.selected = old.selected;
            this.setSelected = old.selected;
          }


          if (!filtersApplied) {
            this.filters = old.filters == "[]" ? {} : old.filters;
          }
          this.onChangeFilters(this.filters, old.ids);
          setTimeout((x) => (this.setSelected = null), 100);
        } else {
          if (filtersApplied) {
            this.onChangeFilters(this.filters);
          } else {
            this.groupDetails();
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.headers = this.defaultHeaders;
  },
  methods: {
    save() {
      let selected = this.preparedDetails.filter(
        (x) => this.selected[x.checkbox_id] === true
      );
      let row = {
        details: this.preparedDetails,
        filters: this.currentFilters,
        params: this.params,
        selected: this.selected,
        main_id:
          this.requestData.prepared && this.requestData.prepared[this.clickedId]
            ? this.requestData.prepared[this.clickedId].main_id
            : null,
        info: {
          average_price: this.averagePrice,
          client_price: this.price,
          sum: this.sum(selected),
          numbers: this.numbers(selected),
        },
      };
      this.$emit("saveRow", row);
      this.hide();
    },
    hide() {
      this.meat = "";
      this.averagePrice = 0;
      this.min = 0;
      this.max = 0;
      this.key = null;
      this.selected = [];
      this.usedDetails = [];
      this.filteredDetails = [];
      this.autocompleteKey = 0;
      this.meat = "";
      this.description = "";
      this.preparedDetails = [];
      this.currentFilters = [];
      this.filters = {};
      this.price = null;
      this.customPrice = null;
      this.$refs.dialog.save();
      this.$refs.filtersComponent.resetPredefinedFiltersSelect();
      this.$emit("hideModal");
    },
    groupDetails(newDetails) {
      let details = this.setGroup(newDetails);
      let totalDetails = [];

      Object.keys(details).forEach((x, i) => {
        const item = details[x][0];
        item["ids"] = details[x].map((item) => item.id);
        item["average_price"] = this.average(details[x]);
        item["client_price"] = this.priceFormule(item["average_price"]);
        item["total_kg"] = this.sum(details[x]);
        item["number"] = this.numbers(details[x]);
        totalDetails.push(item);
      });

      /*
       * Custom logic in that if id column is selected to show and action is not edit
       * we must sort details by price and select lowest price on rank 1 company.
      */
      if ((this.requestData.columns || []).includes('id') && !this.hasOldSelectedData) {
        this.setSelected = {};
        let hasSelected = false;

        totalDetails.forEach((x, i) => {
            if (x.company_rank === '1 - High' && !hasSelected) {
                this.setSelected[i] = true;
                hasSelected = true;
            } else {
                this.setSelected[i] = false;
            }
        });

        this.customPrice = 0;
      }

      this.preparedDetails = totalDetails;
      let headers = JSON.parse(JSON.stringify(this.defaultHeaders));

      (this.requestData.columns || []).forEach((c) =>
        headers.splice(4, 0, {
          text: this.columns.find((f) => f.value === c).name,
          value: c,
          align: "center",
          input: false,
          disabled: false,
        })
      );
      let orderedHeader = window.localStorage.getItem(this.detailsTableName);
      if (orderedHeader) {
        orderedHeader = JSON.parse(orderedHeader);

        headers.forEach((x, i) => {
          let el = orderedHeader.find((e) => e.value === x.value);
          if (el) {
            headers.splice(
              orderedHeader.indexOf(el),
              0,
              headers.splice(i, 1)[0]
            );
          }
        });
      }

      this.headers = headers;

      this.setAveragePrice();
    },
    setGroup(newDetails) {
      let setSelected = false;
      let details = {};

      let tmpDetails = JSON.parse(JSON.stringify(newDetails || this.details));
      if ((this.requestData.columns || []).includes('id')) {
        tmpDetails = _.sortBy(tmpDetails, detail => Number(detail.average_price));
      }
      tmpDetails.forEach((x, i) => {
        let key =
          x.key +
          "_" +
          (this.requestData.columns || []).map((c) => x[c]).join("_") +
          (x.se ? "_" + x.se : "");
        if (!setSelected && x.se) {
          setSelected = true;
        }
        if (!i) {
          this.description = x["M-Description"];
          this.meat = x["M-MeatGroup"];
        }
        if (!details[key]) {
          details[key] = [];
        }

        details[key].push(x);
      });

      if (setSelected) {
        Object.keys(details).forEach((k, i) =>
          details[k].find((f) => f.se === "n")
            ? null
            : (this.selected[i] = true)
        );
      }

      return details;
    },
    average(items) {
      let qty = 0;

      const avg =
        items.reduce((p, c) => {
          let q = parseInt(c.total_kg);
          qty += q;
          return p + parseFloat(c.average_price) * q;
        }, 0) / (qty ? qty : 1);
      return avg.toFixed(2);
    },
    sum(items) {
      const sum = items.reduce(
        (p, c) => p + parseInt(c.total_kg.toString().replace(/,/g, "")),
        0
      );
      return new Intl.NumberFormat("en-BG").format(sum);
    },
    numbers(items) {
      return items.reduce((p, c) => p + parseFloat(c.number), 0);
    },
    minPrice(items) {
      return Math.min
        .apply(
          null,
          items.map(function (item) {
            return parseFloat(item.average_price);
          })
        )
        .toFixed(2);
    },
    maxPrice(items) {
      return Math.max
        .apply(
          null,
          items.map(function (item) {
            return parseFloat(item.average_price);
          })
        )
        .toFixed(2);
    },
    setAveragePrice() {
      let els = this.details;
      if (this.filteredDetails.length > 0) {
        els = this.filteredDetails;
      }
      if (Object.keys(this.selected).length) {
        let selected = Array.prototype.concat.apply(
          [],
          this.preparedDetails
            .filter((x, i) => this.selected[i] === true)
            .map((x) => x.ids)
        );
        els = els.filter((x, i) => selected.indexOf(x.id) !== -1);
      }

      this.averagePrice = this.average(els);
      this.min = this.minPrice(els);
      this.max = this.maxPrice(els);
      this.setPrice(els);
    },
    setPrice(items) {
      if (this.customPrice > 0) {
        return;
      }
      this.price = this.priceFormule(this.averagePrice);
    },
    /**
     * Client price formule
     *
     * If cost per kg (price to us) is less than X, add Y to it
     * where X is a configuration value (for example, 0.83) and Y is also a configuration value (for example (0.05).
     *
     * Else - add margin Z where Z is a configuration value (e.g., 6%).
     * Formula for margin would be: Cost/(1-Z%)
     */
    priceFormule(price) {
      if (!price) {
        return 0;
      }

      price = parseFloat(price);
      if (price < this.config.min_price) {
        return (price + this.config.add_price).toFixed(2);
      }

      if (this.config.percentage > 0) {
        return (price/(1 - (this.config.percentage/100))).toFixed(2);
      }

      return price;
    },
    selectedItems(selected, isInitial) {
      if (!isInitial) {
        this.customPrice = 0;
      }
      this.selected = selected;
      this.setAveragePrice(true);
    },
    onChangeFilters(filters, ids) {
      this.currentFilters = filters;
      let details = JSON.parse(JSON.stringify(this.details));
      if (ids) {
        details.map((x) => (x.se = ids.indexOf(x.id) !== -1 ? "s" : "n"));
      }
      this.filteredDetails = details.filter((item) => {
        return Object.keys(filters).every((key) => {
          return (
            filters[key].length === 0 ||
            (item[key] !== undefined && filters[key].indexOf(item[key]) !== -1)
          );
        });
      });
      this.groupDetails(this.filteredDetails);
    },
    changePrice(item) {
      item.custom_price = item.client_price;
      this.setAveragePrice();
    },
    showDialog(show) {
      this.show = show;
    },
    getContentOnHover(event, item) {
      return this[event](item);
    },
    showMinMax(item) {
      let details = JSON.parse(JSON.stringify(this.details)).filter(
        (x) => item.ids.indexOf(x.id) !== -1
      );
      let min = this.minPrice(details);
      let max = this.maxPrice(details);
      return `<p>min: ${min}</p><p>max: ${max}</p>`;
    },
    showOffer(item) {
        let state = TableService.getState(this.offerTableName);
        state.meta.search = "";
        state.filterScenarios = [{
            active:true,
            edit:true,
            filters:[{
                component: "enum",
                data: "vendor_offers.id",
                label: "ID",
                mode: null,
                type: "enum",
                value: item.ids,
            }],
            name: `price list ${this.requestData.name}`
        }];
        TableService.setState(state,this.offerTableName)
      let routeData = this.$router.resolve({
        name: "list-offers"
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>


<style scoped>
.price-input,
.price-input:focus {
  width: 80px;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}
</style>