<template>
  <div>
    <div class="font-weight-bold ml-8 mb-3" style="font-size: 20px">
     <i class="fa fa-home"></i> {{ name.toUpperCase() }}
    </div>

    <v-list>
      <v-list-group
        v-for="(items, key) in data"
        :key="key"
        :value="true"
        no-action
        disabled
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              ><strong class="pl-4"> {{ key.toUpperCase() }}</strong></v-list-item-title
            >
          </v-list-item-content>
        </template>

        <v-list-item class="remove-padding">
          <v-list-item-content>
            <v-list-item-title>
              <v-timeline dense>
                <v-timeline-item
                  v-for="(item, i) in items"
                  :key="i"
                  :color="'red'"
                  small
                >
                  <div class="pt-1">
                    <div class="font-weight-normal">
                      <strong >
                        {{ item["M-Description"] }}{{ item["note"] || null?', '+item["note"]:'' }}{{ item["M-Packaging"] || null?', '+item["M-Packaging"]+ ' - ':' - ' }}
                        {{ item["client_price"] ? formatPrice(item["client_price"]) : formatPrice(item["price"]) }} €/kg {{item['M-Loading']}}
                      </strong>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import PricingService from "@/common/pricing.service.js";
import { GET_PRICING_TYPES } from "@/store/pricing.module";
import validations from "@/views/partials/form/Validations.vue";

export default {
  props: ["requestData"],
  components: {},
  data() {
    return {
      data: {},
      columns: [],
      name: "",
      validations: validations,
      selectChange: 0,
      pricingPublishedVia: [],
      types: [],
      tags: [],
      dType: 1,
      dateTypes: [
        { id: 1, name: "weeks" },
        { id: 2, name: "calendar" },
      ]
    };
  },
  watch: {
    requestData: {
      handler: function (newValue) {
        this.data = PricingService.prepareData(newValue.prepared || {});
        this.columns = newValue.additional_tags || [];
        this.name = newValue.name || "";
      },
      deep: true,
    },
  },
  mounted() {
    this.getPricingPublishingVia();
  },
  methods: {
    getPricingPublishingVia() {
      this.$store.dispatch(GET_PRICING_TYPES).then((response) => {
        this.pricingPublishedVia = response;
      });
    },

    // hardcoded style assent@ 6/10 - needs refactoring to be global and possibly consider system wide setting
    formatPrice(price) {
        const formatter = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        });
        return formatter.format(price);
    }
  },
};
</script>

<style>
.remove-padding{
    margin-left: -72px !important;
}
</style>