<template>
    <v-combobox
      v-bind="$attrs"
      :value="localValue"
      @input="handleInput"
      @change="handleInput"
      @update:search-input="handleInput"
    />
</template>

<script>

export default {
  props: ['value'],

  data () {
    return {
      localValue: this.value
    }
  },

  methods: {
    handleInput(event) {
      if (event !== null) {
        this.$emit('input', event);
        this.$emit('change', event);
      }
    }
  }
};
</script>
