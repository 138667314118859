<template>
  <v-card>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          v-model="requestData.published_via"
          label="Published Via"
          prepend-icon="mdi-share"
          :items="pricingPublishedVia"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <TPDatePicker
          label="Published Date"
          :value="requestData.published_date"
          v-model="requestData.published_date"
          :key="datepickerKey"
        ></TPDatePicker>
      </v-col>
        <v-col cols="12" sm="6">
        <v-select
          v-model="requestData.users"
          label="Share to User"
          prepend-icon="mdi-user"
          :items="users"
          item-text="name"
          item-value="id"
          multiple
        ></v-select>
      </v-col>
        <v-col cols="12" sm="6">
        <v-select
          v-model="requestData.teams"
          label="Share to Team"
          prepend-icon="mdi-team"
          :items="teams"
          item-text="name"
          item-value="id"
          multiple
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import { GET_PRICING_TYPES } from "@/store/pricing.module";
import { GET_TEAMS_REQUEST } from "@/store/teams.module";
import { GET_USERS } from "@/store/users.module";
import TPDatePicker from "@/views/partials/form/DatePicker.vue";
import validations from "@/views/partials/form/Validations.vue";

export default {
  props: ["requestData"],
  components: { TPDatePicker },
  data() {
    return {
      validations: validations,
      datepickerKey: 0,
      pricingPublishedVia: [],
      teams: [],
      users: [],
      dType: 1,
      dateTypes: [
        { id: 1, name: "weeks" },
        { id: 2, name: "calendar" },
      ],
    };
  },
  watch:{
      requestData: {
      handler: function (newValue) {
        this.datepickerKey++
      },
      deep: true,
    },
  },
  mounted() {
    this.getPricingPublishingVia();
    this.getTeams();
    this.getUsers();
  },
  methods: {
    getPricingPublishingVia() {
      this.$store.dispatch(GET_PRICING_TYPES).then((response) => {
        this.pricingPublishedVia = response;
      });
    },
    getTeams() {
      this.$store.dispatch(GET_TEAMS_REQUEST).then((response) => {
        this.teams = response;
      });
    },
    getUsers() {
      this.$store.dispatch(GET_USERS).then((response) => {
        this.users = response;
      });
    },
  },
};
</script>

<style>
</style>