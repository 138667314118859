<template>
  <v-card>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="requestData.additional_tags"
          label="Additional Columns"
          :items="additionalColumns"
          prepend-icon="mdi-tag"
          item-text="name"
          item-value="value"
          multiple
          @change="getTagsDetails()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <MainTableFilters
          :filters="filters"
          @search="handleSearch"
          @filters-change="handleFiltersChange"
        />
        <Table
          :loading="isProcessing"
          :headers="headers"
          :items="filteredDetails"
          :hideFilters="true"
          @clone="clone"
          @remove="remove"
          @reset="reset"
          @edit="rowClick"
          @changeEvent="change"
          :notSpecial="true"
        >
          <template v-slot:select="{ header, item }">
            <EditColumn
              :column="header.value"
              :item="item"
              @save="changeColumn"
              :requestData="requestData"
            ></EditColumn>
          </template>
        </Table>
      </v-col>
    </v-row>
    <Details
      ref="details"
      @hideModal="hideModal"
      @saveRow="saveRow"
      :details="rowDetails"
      :clickedId="clickedId"
      :params="params"
      :requestData="requestData"
      :columns="columns"
      :config="config"
      :detailsTableName="detailsTableName"
      :filtersValues="filtersValues"
      @reloadData="reloadData"
    ></Details>
    <Confirm ref="confirm" />
  </v-card>
</template>


<script>
import { GET_MAPPING_WITH_GROUP } from "@/store/mappings.module";
import { GET_GROUPED_DETAILS,GET_TAG_PRICING } from "@/store/pricing.module";
import Details from "./Details.vue";
import MainTableFilters from "./MainTableFilters.vue";
import EditColumn from "./EditColumn.vue";
import Table from "@/views/partials/inlineEditTable/Table.vue";
import Confirm from "@/views/partials/dialogs/NewConfirm.vue";
import {
  GET_PRICE_CONFIG,
} from "@/store/price_config.module";

export default {
  components: { Details, Table, EditColumn, Confirm, MainTableFilters },
  props: ["requestData", "mappingDetails"],
  data() {
    return {
      headers: [
        {
          text: "Description",
          value: "value",
          align: "center",
          input: false,
          disabled: false,
          onClick:"edit"
        },
        {
          text: "Meat",
          value: "group",
          align: "center",
          input: false,
          disabled: false,
             onClick:"edit"
        },
        {
          text: "Number",
          value: "number",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
             onClick:"edit"
        },
        {
          text: "Vendor Price",
          value: "average_price",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
             onClick:"edit"
        },
        {
          text: "Client Price",
          value: "client_price",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
             onClick:"edit"
        },
        {
          text: "Quantity",
          value: "total_kg",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
             onClick:"edit"
        },
        {
          text: "Note",
          value: "note",
          align: "center",
          input: true,
          disabled: false,
          noFilterable: true
        },
        {
          text: 'M-Packaging',
            value: 'M-Packaging',
            align: "center",
            input: false,
            disabled: false,
            combo: true,
        },
        {
          text: 'M-Loading',
            value: 'M-Loading',
            align: "center",
            input: false,
            disabled: false,
            combo: true,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          input: false,
          disabled: false,
          noFilterable: true,
          buttons: [
            {
              action: "clone",
              icon: "clone",
              color: "blue",
            },
            {
              action: "edit",
              icon: "edit",
              color: "green",
            },
            {
              action: "remove",
              icon: "times",
              color: "red",
            },
            {
              action: "reset",
              icon: "redo-alt",
              color: "warning",
            },
          ]
        },
      ],
      defaultHeader: [
        "value",
        "group",
        "number",
        "average_price",
        "client_price",
        "total_kg",
        "actions",
        "note",
        "M-Packaging",
        "M-Loading"
      ],
      filters: {},
      filtersValues: {},
      searchString: null,
      isProcessing: false,
      tags: [],
      type: null,
      tagsDetails: {},
      tableKey: 0,
      details: [],
      filteredDetails: [],
      rowDetails: [],
      params: {},
      columns: [],
      additionalColumns: [],
      additionalTags: null,
      clickedId: null,
      config:{},
      detailsTableName: "pricingDetails"
    };
  },
  watch: {
    requestData: {
      handler: function (newValue) {
        if (newValue.type_id !== this.type) {
          this.type = newValue.type_id;
          this.getTags();
        }

        if (newValue.additional_tags) {
            this.additionalTags = [...newValue.additional_tags];
        }
      },
      deep: true,
    },
    mappingDetails: function (newValue) {
      if (newValue.details) {
        this.details = newValue.details;
        this.filteredDetails = [...newValue.details];
        this.additionalColumns = newValue.columns;
        this.getTagsDetails(true);
        this.prepareFilters();
      }
    },
    details: function () {
      this.applyFilters();
    }
  },
  mounted() {
      this.getConfig();
  },
  methods: {
    prepareFilters() {
      let noFilterableColumns = [];
      this.headers.forEach(header => {
        if (header.noFilterable) {
          noFilterableColumns.push(header.value);
        }
      });

      this.filters = {};
      this.details.forEach(detail => {
        if (detail.offers) {
          detail.offers.forEach(offer => {
            for (const [column, value] of Object.entries(offer)) {
              if (noFilterableColumns.indexOf(column) === -1 && value !== null) {
                let labelColumn = this.additionalColumns.find(col => col.value === column);
                let label = labelColumn ? labelColumn.name : column;

                if (!this.filters[column]) {
                  this.filters[column] = {
                    label: label,
                    items: []
                  };
                }

                if (this.filters[column].items.indexOf(value) === -1) {
                  this.filters[column].items.push(value);
                }
              }
            }
          });
        }
      });
    },

    handleSearch(searchString) {
      this.searchString = searchString.length > 2 ? searchString : null;
      this.applyFilters();
    },

    handleFiltersChange(filters) {
      this.filtersValues = filters;
      this.applyFilters();
    },

    applyFilters() {
      this.filteredDetails = [...this.details];

      if (!this.searchString && !Object.values(this.filtersValues).length) {
        return;
      }

      this.filteredDetails = this.filteredDetails.filter(detail => {
        let hasSearchedOffer = false;

        if (detail.offers) {
          detail.offers.forEach(offer => {
            //Filters
            let hasFilterMatch = true;
            if (Object.values(this.filtersValues).length) {
              for (const [key, filterValues] of Object.entries(this.filtersValues)) {
                let hasValueMatch = false;

                filterValues.forEach(value => {
                  if (offer[key] && offer[key] === value) {
                    hasValueMatch = true;
                  }
                });

                if (!hasValueMatch) {
                  hasFilterMatch = false;
                }
              }
            }

            //Search
            let hasSearchMatch = true;
            if (this.searchString) {
              hasSearchMatch = false;
              Object.values(offer).forEach(value => {
                if (String(value).includes(this.searchString)) {
                  hasSearchMatch = true;
                }
              });
            }

            if (hasSearchMatch && hasFilterMatch) {
                hasSearchedOffer = true;
              }
          });
        }

        return hasSearchedOffer;
      });
    },

    getTagsDetails(initial) {
        let additionalTags = this.requestData.additional_tags || this.additionalTags;

      if (!additionalTags) {
          return;
      }
      let headers = this.headers.filter(
        (x) => this.defaultHeader.indexOf(x.value) !== -1
      );
      this.additionalColumns.forEach((x, i) => {
        if (additionalTags.indexOf(x.value) !== -1) {
          headers.splice(headers.length - 1, 0, {
            text: x.name,
            value: x.value,
            align: "center",
            input: false,
            disabled: false,
            combo: true,
          });
        }
      });
      this.headers = headers;

      if (!initial) {
        this.$emit('setParams');
      }
    },
    getTags() {
      if (!this.requestData.type_id) {
        return;
      }
      this.$store
        .dispatch(GET_MAPPING_WITH_GROUP, {
          q: this.requestData.type_id,
          except_group: true,
        })
        .then((response) => {
          this.tags = response;
        });
    },
    openDetailInfo(detail) {
        console.log(detail);
      this.rowInfo = detail;
      this.$refs.details.showDialog(true);
    },
    hideModal() {
    },
    saveRow(item) {
      item.params.lastEdited = true;
      this.requestData.prepared[this.clickedId] = item;
      this.details.map((x) => {
        let row = this.requestData.prepared[x.row_id];
        if (row) {
          x.isModified = true;
          x.number = row.info.numbers;
          x.client_price = 0;
          x.client_price = row.info.client_price;
          x.average_price = row.info.average_price;
          x.total_kg = row.info.sum;
          let details = row.details.filter(
            (d) => row.selected[d.checkbox_id] === true
          );

          this.additionalColumns.forEach((h) => {
            if (
              details.length > 0
            ) {
              x[h.value] = details[0][h.value];
            }
          });

          // if (details.length > 0) {
          //   for (const [key, value] of Object.entries(details[0])) {
          //     x[key] = value;
          //   }
          // }
        }

        return x;
      });

      this.$emit('setParams');
    },
    change() {
      console.log("change");
    },
    rowClick(item) {
         if(!item.average_price){
          return;
      }
      this.getRowDetails(item);
      this.$refs.details.showDialog(true);
    },
    getRowDetails(item) {
      let params = item;
      params["data"] = {
        date_type: this.requestData.date_type,
        date: this.requestData.date,
        expire_date: this.requestData.expire_date,
        type_id: this.requestData.type_id,
        tag_id: this.requestData.tag_id,
        week: this.requestData.week,
      };
      this.$store.dispatch(GET_GROUPED_DETAILS, params).then((response) => {
        this.columns = response.columns;
        this.rowDetails = response.details;
        this.params = item;
        this.clickedId = item.row_id;
      });
    },
    clone(item) {
      let newRow = JSON.parse(JSON.stringify(item));
      newRow.isModified = false;
      newRow.row_id =
        Math.max.apply(
          Math,
          this.details.map(function (o) {
            return o.row_id;
          })
        ) + 1;
      this.details.splice(this.details.indexOf(item) + 1, 0, newRow);
    },
    async reset(item) {
      if (await this.$refs.confirm.open("You want to reset this record?")) {
         this.$emit('processing',true)
          Object.keys(this.requestData.prepared).forEach(k => this.requestData.prepared[k].params.uuid === item.uuid?delete this.requestData.prepared[k]:'');
          let params = {
                    date_type: this.requestData.date_type,
                    date: this.requestData.date,
                    expire_date: this.requestData.expire_date,
                    type_id: this.requestData.type_id,
                    tag_id: this.requestData.tag_id,
                    week: this.requestData.week,
                };
            this.$store.dispatch(GET_GROUPED_DETAILS, {data: params}).then((response) => {
                let key = item['M-MeatGroup']+"_"+item['M-Description'];
               this.details = this.details.map(x => {
                    if(x.uuid === item.uuid){
                        x =  {...x,...response.details[key]}
                        x.isModified = false;
                    }
                    return x;
                })
                   this.$emit('processing',false);
            });
      }
    },
    async remove(item) {
      if (await this.$refs.confirm.open("You want to delete this record?")) {
        this.removeRow(item);
      }
    },
    removeRow(item) {
      this.details.splice(this.details.indexOf(item), 1);
      delete this.requestData.prepared[item.row_id];
       this.$emit('setParams');
    },
    changeColumn(item) {
      this.details[this.details.indexOf(item.item)][item.column] = item.value;
       this.$emit('setParams');
    },
    getOptions(item, column) {
      this.getRowDetails(item);
      return this.rowDetails.map((x) => x[column]);
    },
    change(item) {
      if (this.requestData.prepared && this.requestData.prepared[item.row_id]) {
        this.requestData.prepared[item.row_id].params = item;
      }
       this.$emit('setParams');
    },
    getConfig(){
        this.$store
        .dispatch(GET_PRICE_CONFIG)
        .then((response) => {
          this.config = response;
        })
        .catch((error) => {

        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    reloadData(item){
        this.getRowDetails(item);
    }
  },
};
</script>

<style>
</style>