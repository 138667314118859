<template>
  <div class="text-center">
    <v-card>
      <v-list style="height: 50vh; overflow: scroll">
        <template v-for="(column, i) in columns">
          <v-list-item :key="i">
            <v-list-item-action class="mr-0 mt-0 mb-0">
              <v-autocomplete
                :items="data"
                v-model="filters[column.value]"
                :item-text="column.value"
                :item-value="column.value"
                class="filter-autocomplete"
                multiple
                dense
                chips
                clearable
                solo
                deletable-chips
                small-chips
                @change="onChange"
                :label="column.name"
              >
            <template v-slot:item="props">
                <template >
                  <v-list-item-content :class="{'text-gray':checkIsExists(props.item,column.value)}" v-text="props.item[column.value]"></v-list-item-content>
                </template>
              </template>
              </v-autocomplete>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <v-card-actions class="sticky-footer">
        <v-select
          ref="predefinedFiltersSelect"
          v-model="selectedPredefinedFilter"
          label="Predefined Filters"
          prepend-icon="fa fa-filter"
          :items="predefinedFilters"
          item-text="name"
          item-value="id"
          return-object
          :menu-props="{ top: true, offsetY: true, closeOnContentClick: true }"
        >
          <template v-slot:item="{ item }">
            <v-list-item @click="handlePredefinedFiltersChange(item)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="red" @click="handleDeletePredefinedFilterClick(item, $event)">mdi-delete</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <v-divider class="mb-2"></v-divider>
            <v-list-item @click="openSaveAsNewDialog">
              <v-list-item-avatar color="grey lighten-3">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Save as New
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="filters = [];onChange()">
          Clear Filters
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="saveAsNewDialog"
      persistent
      max-width="400px"
      @keydown.esc="dialog = false"
      @keydown.enter="store('store')"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New Filter</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-form
                    ref="newFilterForm"
                    v-model="validNewFilterForm"
                    @submit.prevent
                >
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field
                        label="Name"
                        v-model="newFilterName"
                        prepend-icon="info"
                        :rules="[validations.required()]"
                        :error-messages="error"
                        @input="clearValidation"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveAsNewDialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="processingNewFilter" @click="saveAsNew">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Confirm
        v-model="deletePredefinedFiltersConfirm"
        content="Please confirm filter deleting"
        @confirm="deletePredefinedFilter"
        @decline="predefinedFilterForDelete = null"
    ></Confirm>
  </div>
</template>

<script>
import _ from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import validations from "@/views/partials/form/Validations.vue";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import { GET_USER_FILTERS } from "@/store/userFilter.module";
import {
  STORE_USER_FILTER,
  DELETE_USER_FILTERS
} from "@/store/userFilter.module";

export default {
  components: {
    Confirm
  },
  props: ["columns", "data","filteredDetails","setFilters", "detailsTableName"],
  data() {
    return {
      filters: {},
      headers: {},
      predefinedFilters: [],
      selectedPredefinedFilter: null,
      validations: validations,
      menu: false,
      saveAsNewDialog: false,
      newFilterName: "",
      validNewFilterForm: false,
      error: "",
      processingNewFilter: false,
      predefinedFilterForDelete: null,
      deletePredefinedFiltersConfirm: false
    };
  },
  watch: {
      setFilters: function(val){
          this.filters = val;
      }
  },
  mounted() {
    this.getPredefinedFilters();
  },
  methods: {
      getPredefinedFilters(){
        this.$store
        .dispatch(GET_USER_FILTERS, this.detailsTableName)
        .then((response) => {
          this.predefinedFilters = response;
        });
      },
      onChange(){
          this.$emit('filters',this.filters);
          this.resetPredefinedFiltersSelect();
      },
      resetPredefinedFiltersSelect() {
        this.$refs.predefinedFiltersSelect.reset();
      },
      checkIsExists(item,column){
          try {
              return this.filteredDetails.length !== 0 && this.filteredDetails.filter(x => x && x[column] && x[column].indexOf(item[column]) !== -1).length === 0;
          } catch (error) {
              return true;
          }
      },
      handlePredefinedFiltersChange(filter) {
        this.filters = { ...filter.filters };
        this.selectedPredefinedFilter = filter;
        this.$emit('filters',this.filters);
      },
      handleDeletePredefinedFilterClick(filter, event) {
        event.stopPropagation();
				this.predefinedFilterForDelete = filter;
				this.deletePredefinedFiltersConfirm = true;
      },
      deletePredefinedFilter() {
        let vm = this;
        this.$store
          .dispatch(DELETE_USER_FILTERS, this.predefinedFilterForDelete.id)
          .then(() => {
            vm.predefinedFilters = _.filter(vm.predefinedFilters, (filter) => {
                return this.predefinedFilterForDelete.id !== filter.id;
            });

            vm.$store.dispatch(SHOW_SNACK, {
              message: "Filter deleted successfuly."
            });
          })
          .catch((error) => {
            vm.$store.dispatch(SHOW_SNACK, {
              message: "Something went wrong.",
              type: "error"
            });
          }).finally(() => {
            this.predefinedFilterForDelete = null;
          });
      },
      openSaveAsNewDialog() {
        this.saveAsNewDialog = true;
        this.$nextTick(() => {
          this.clearValidation();
        });
      },
      clearValidation() {
        this.error = "";
        this.$refs.newFilterForm.resetValidation();
      },
      saveAsNew() {
        this.$refs.newFilterForm.validate();

        if (!this.validNewFilterForm) {
          return;
        }

        if (!Object.keys(this.filters).length) {
            this.error = "Please select filters";
            return;
        }

        this.processingNewFilter = true;

        const params = {
            basic: 1,
            name: this.newFilterName,
            type: this.detailsTableName,
            filters: this.filters
        };

        let vm = this;
        this.$store
          .dispatch(STORE_USER_FILTER, params)
          .then((data) => {

            vm.predefinedFilters.push(data);

            vm.$store.dispatch(SHOW_SNACK, {
              message: "Filter stored successfuly.",
            });

            vm.newFilterName = "";
            vm.error = "";
            vm.clearValidation();
            vm.saveAsNewDialog = false;
          })
          .catch((error) => {
            this.error =
              Object.keys(error.errors).length > 0 &&
              error.errors[Object.keys(error.errors)[0]].length > 0
                ? error.errors[Object.keys(error.errors)[0]][0]
                : "";
          })
          .finally(() => {
            this.processingNewFilter = false;
          });
      }
  },
};
</script>

<style lang="sass" scoped>
:deep(.v-text-field__details)
    margin-bottom: 0 !important
</style>