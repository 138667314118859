import {   PRICING_EXPORT_RAW  } from "@/store/pricing.module";
import TableService from "@/common/table.service";
import { SHOW_SNACK } from "@/store/snack.module";
import store from "@/store";

// hardcoded style assent@ 6/10 - needs refactoring to be global and possibly consider system wide setting
function formatPrice(price) {
    const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
    return formatter.format(price);
}

export default {
    prepareData(items) {
      const groupKey = "M-MeatGroup";
      const valueKey = "M-Description";
      const orderedDescriptionsKey = 5;
      const orderedDescriptions = store.getters.orderedMappingValues[orderedDescriptionsKey];

      let itemsKeys = Object.keys(items);

      if (orderedDescriptions && orderedDescriptions.length) {
        let itemsKeysTemp = [];

        orderedDescriptions.forEach((orderedKey) => {
          for (const [itemKey, item] of Object.entries(items)) {
            let key = item.params[groupKey] + '_' + item.params[valueKey];
            if (key === orderedKey) {
              itemsKeysTemp.push(itemKey)
            }
          }
        });

        if (itemsKeysTemp.length === itemsKeys.length) {
          itemsKeys = itemsKeysTemp;
        }
      }

      return itemsKeys.reduce(function(rv, x) {
          (rv[items[x].params[groupKey]] = rv[items[x].params[groupKey]] || []).push(
              items[x].params
          );
          return rv;
      }, {});
    },

    getContent(items) {
        let data = this.prepareData(items);

        let html = "";
        Object.keys(data).forEach(k => {
            html += `<p><strong>${k}</strong></p>`;
            data[k].forEach(item => {
                let line = `<p><strong>${item["M-Description"]}${
                    item["note"] || null ? ", " + item["note"] : ""
                }${
                    item["M-Packaging"] || null
                        ? ", " + item["M-Packaging"] + " - "
                        : " - "
                }`;
                line += `${
                    item["client_price"] ? formatPrice(item["client_price"]) : formatPrice(item["price"])
                } €/kg ${item["M-Loading"]?item["M-Loading"]:''}</strong></p>`;
                html += line;
            });
        });
        return html;
    },
    download(items,name) {
        var element = document.createElement("a");

        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," +
                encodeURIComponent(
                    this.getContent(items)
                        .replace(/<p><strong>/g, "")
                        .replace(/<\/strong><\/p>/g, "\n")
                        .replace(/<p>/g,"")
                        .replace(/<\/p>/g,"\n")
                )
        );
        element.setAttribute("download", name + ".txt");

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    },
     downloadRaw(prepared,store) {
      let offerTableName = 'VueTable_vendorOffersTable_preferences';
      this.showStartExportNotification(store);
      let state = TableService.getState(offerTableName);
      let ids = Array.prototype.concat.apply( [],Object.keys(prepared).map(k => prepared[k].ids));
      state.meta.search = "";
      state.filterScenarios = [
            {
              component: "enum",
              data: "vendor_offers.id",
              label: "ID",
              mode: null,
              type: "enum",
              value: ids
            }
        ];
       store
        .dispatch(PRICING_EXPORT_RAW, {meta:state.meta,internalFilters:state.filterScenarios,columns:state.columns})
        .then((resource) => {
         this.showExportNotification(resource,store)
        })
    },
     showStartExportNotification(store) {
        store.dispatch(SHOW_SNACK, {
        message: `Export started in the background. Please wait...`,
        timeout: 200000,
        type: "warning",
      });
    },
    showExportNotification(params,store) {
        store.dispatch(SHOW_SNACK, {
        message: `Export successfull. Please click <a target="_blank" href="${params}">here</a> to download your file.`,
        timeout: 200000,
      });
    },
};
