<template>
  <div>
    <KTPortlet v-if="hasPermissions(['create-pricing', 'edit-pricing'])">
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-card :disabled="isProcessing" :loading="isProcessing">
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="success"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-tabs v-model="tab" class="price-color" left dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <v-toolbar-title>
                {{ getTitle() }}
              </v-toolbar-title>
            </v-tab>
            <v-spacer></v-spacer>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-form
                @submit.prevent
                ref="requestData"
                v-model="validRequestData"
              >
                <v-stepper v-model="done">
                  <v-stepper-header>
                    <v-stepper-step
                      step="1"
                      class="set-cursor-pointer"
                      @click="nextStep(1)"
                    >
                      Base
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      step="2"
                      class="set-cursor-pointer"
                      @click="nextStep(2)"
                      >Price List</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step
                      step="3"
                      class="set-cursor-pointer"
                      @click="nextStep(3)"
                    >
                      Preview
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step
                      step="4"
                      class="set-cursor-pointer"
                      @click="nextStep(4)"
                    >
                      Publish
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <Step1
                       @processing="processing"
                        :requestData="requestData"
                        @handleDateInput="handleDateInput"
                        @mappingDetails="setMappingDetails"
                        ref="stepOne"
                      ></Step1>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" v-on:click="back(true)"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" @click="nextStep(2)">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <Step2
                       @processing="processing"
                        :requestData="requestData"
                        :mappingDetails="mappingDetails"
                        @setParams="setParams"
                      ></Step2>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" v-on:click="nextStep(1)"
                          >Back</v-btn
                        >
                        <v-btn color="primary" @click="nextStep(3)">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <Step3  @processing="processing" :requestData="requestData"></Step3>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" v-on:click="nextStep(2)"
                          >Back</v-btn
                        >
                        <HtmlEditor :getContent="getContent"></HtmlEditor>
                        <v-btn color="success" v-on:click="download()"
                          >Download</v-btn
                        >
                        <v-btn color="info" v-on:click="downloadRaw()"
                          >Download Raw Offers</v-btn
                        >
                        <v-btn color="primary" v-on:click="nextStep(4)"
                          >Continue</v-btn
                        >
                      </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                      <Step4
                       @processing="processing"
                        :requestData="requestData"
                        :mappingDetails="mappingDetails"
                      ></Step4>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" v-on:click="nextStep(3)"
                          >Back</v-btn
                        >
                        <v-btn color="primary" v-on:click="store()">Save</v-btn>
                      </v-card-actions>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
    </KTPortlet>
    <KTPortlet v-else>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-card :disabled="isProcessing" :loading="isProcessing">
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="success"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-tabs v-model="tab" class="price-color" left dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <v-toolbar-title>
                {{ getTitle() }}
              </v-toolbar-title>
            </v-tab>
            <v-spacer></v-spacer>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <Step1
               @processing="processing"
                v-show="false"
                :requestData="requestData"
                @handleDateInput="handleDateInput"
                @mappingDetails="setMappingDetails"
                ref="stepOne"
              ></Step1>
              <Step3 @processing="processing" :requestData="requestData"></Step3>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions>
            <v-spacer></v-spacer>
            <HtmlEditor :getContent="getContent"></HtmlEditor>
            <v-btn color="warning" v-on:click="back()">Back</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import {
  UPDATE_PRICING,
  CREATE_PRICING,
  GET_PRICING
} from "@/store/pricing.module";
import { SHOW_SNACK } from "@/store/snack.module";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";
import Table from "@/views/partials/inlineEditTable/Table.vue";
import Step1 from "./partials/Step1.vue";
import Step2 from "./partials/Step2.vue";
import Step3 from "./partials/Step3.vue";
import Step4 from "./partials/Step4.vue";
import HtmlEditor from "@/views/partials/content/HtmlEditor.vue";
import PricingService from "@/common/pricing.service.js";
import { SEARCH_COMPANY_CONTACTS } from "@/store/companyContact.module";
import { GET_ORDERED_MAPPING_VALUES } from "@/store/mappingValues.module";
import moment from "moment";

export default {
  components: {
    KTPortlet,
    TPConfirm,
    Table,
    Step1,
    Step2,
    Step3,
    Step4,
    HtmlEditor,
  },
  name: "StorePricing",
  data() {
    return {
      id: null,
      tab: "tab-1",
      isProcessing: false,
      requestData: {
        columns:['id'],
        details: [],
        elements: {},
        prepared: {},
        users: [],
        teams: [],
        datepickerKey: 0,
        type_id: 1,
        tag_id: 5,
      },
      validRequestData: false,
      pricingPublishedVia: [],
      done: 1,
      mappingDetails: [],
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.selectPricing();

    this.$store.dispatch(GET_ORDERED_MAPPING_VALUES, 5);
  },
  methods: {
    nextStep(n) {
      if (this.done < n) {
        this.$refs.requestData.validate();

        if (!this.validRequestData) {
          return;
        }
      }
      this.done = n;
    },
    handleDateInput(date, setDefult, week) {
      if (setDefult) {
        date = moment().format("YYYY-MM-DD");
        this.requestData.date = date;
        if (week) {
          this.requestData.date_type = 1;
        }
        this.requestData.week = moment().format("Y-W");
      }
      this.requestData.expire_date = moment(date)
        .add(7, "days")
        .format("YYYY-MM-DD");

      this.requestData.datepickerKey++;
    },
    selectPricing() {
      if (!this.id && !this.$route.params.clone_id) {
        this.handleDateInput("", true, true);
        this.$refs.stepOne.getTag();
        return;
      }

      this.isProcessing = true;
      this.$store
        .dispatch(GET_PRICING, this.id ? this.id : this.$route.params.clone_id)
        .then((response) => {
          if (response.company_contact_id) {
            this.$store
              .dispatch(SEARCH_COMPANY_CONTACTS, response.company_contact_id)
              .then((r) => {
                this.setCloneDetails(response);
              })
              .finally(() => {});
          } else {
            this.setCloneDetails(response);
          }

          this.$nextTick(() => this.requestData.datepickerKey++);
        })
        .catch((error) => {})
        .finally(() => {
          this.isProcessing = false;
        });
    },
    setCloneDetails(response) {
      if (this.$route.params.clone_id) {
        response.details.map((x) => {
          delete x.ids;
          x.main_id = null;
        });
        this.requestData = response;
        this.handleDateInput("", true);
      } else {
        this.requestData = response;
      }
      this.$nextTick(() => this.$refs.stepOne.getTag());
    },
    store() {
      this.$refs.requestData.validate();

      if (!this.validRequestData) {
        return;
      }

      this.isProcessing = true;

      let url = CREATE_PRICING;
      let params = this.prepareStoreData();
      if (this.id) {
        url = UPDATE_PRICING;
        params = {
          id: this.id,
          params: params,
        };
      }

      this.$store
        .dispatch(url, params)
        .then((resource) => {
          this.$store.dispatch(SHOW_SNACK, {
            message: "Price list stored successfuly.",
          });
          if (resource.data) {
            this.id = resource.data.id;
          }
        })
        .catch((response) => {})
        .finally(() => {
          this.isProcessing = false;
          this.$refs.requestData.resetValidation();
        });
    },
    back() {
      this.$router.push({ name: "pricing" });
    },
    change(items) {
      this.requestData.details = items;
      this.store();
    },
    getTitle() {
      return this.$route.params.id
        ? this.$t("PRICING.EDIT")
        : this.$t("PRICING.CREATE");
    },
    setMappingDetails(e) {
      this.mappingDetails = e;
      this.setParams();
    },
    setParams() {
      let el = this.requestData;
      this.requestData = {};
      this.$nextTick(() => (this.requestData = el));
    },
    prepareStoreData() {
      let data = {
        name: this.requestData.name,
        date: this.requestData.date,
        expire_date: this.requestData.expire_date,
        date_type: this.requestData.date_type,
        company_contact_id: this.requestData.company_contact_id,
        columns: this.requestData.columns,
        additional_tags: this.requestData.additional_tags,
        published_via: this.requestData.published_via,
        published_date: this.requestData.published_date,
        tag_id: this.requestData.tag_id,
        type_id: this.requestData.type_id,
        week: this.requestData.week,
        users: this.requestData.users,
        teams: this.requestData.teams,
        details: [],
      };

      Object.keys(this.requestData.prepared).forEach((k) => {
        let row = this.requestData.prepared[k];
        let selected = row.details.filter(
          (d) => row.selected[d.checkbox_id] === true
        );
        data.details.push({
          average_price: row.params.average_price,
          price: row.params.client_price,
          qty: row.params.total_kg.toString().replace(/,/g, ""),
          mapping_uuid: row.params.uuid,
          main_id: row.main_id,
          ids: Array.prototype.concat.apply(
            [],
            selected.map((s) => s.ids)
          ),
          filters: row.filters,
          meta: row.params,
        });
      });

      return data;
    },
    getContent() {
      let items = this.requestData.prepared;
      return PricingService.getContent(items);
    },
    download() {
      PricingService.download(this.requestData.prepared, this.requestData.name);
    },
    downloadRaw(){
        PricingService.downloadRaw(this.requestData.prepared,this.$store);
    },
    processing(process){
        this.isProcessing = process;
    }

  },
};
</script>

<style>
</style>