<template>
  <v-card>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="requestData.name"
          label="Name"
          prepend-icon="mdi-text"
          :rules="[validations.required()]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <keep-alive>
          <TPContactsAutocomplete
            v-model="requestData.company_contact_id"
            @processing="handleProcessing"
            @processed="handleProcessed"
            :notRequired="true"
            @input="contactChange"
          ></TPContactsAutocomplete>
        </keep-alive>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          v-model="requestData.date_type"
          label="Date Type"
          prepend-icon="mdi-calendar"
          :items="dateTypes"
          :key="requestData.datepickerKey || 0"
          item-text="name"
          item-value="id"
          :rules="[validations.required()]"
          @change="requestData.datepickerKey++"
        ></v-select>
      </v-col>
      <template v-if="requestData.date_type === 1">
        <v-col cols="12" sm="6">
          <v-select
            v-model="requestData.week"
            label="Week"
            prepend-icon="mdi-calendar"
            :items="getYearWeeks()"
            item-text="id"
            item-value="id"
            :rules="[validations.required()]"
            @change="dataTypeChange()"
          ></v-select>
        </v-col>
      </template>
      <template v-if="requestData.date_type === 2">
        <v-col cols="12" sm="6">
          <TPDatePicker
            label="Date"
            v-model="requestData.date"
            :key="requestData.datepickerKey || 0"
            :rules="[validations.required()]"
            @input="
              $emit('handleDateInput', requestData.date);
              dataTypeChange();
            "
          ></TPDatePicker>
        </v-col>
        <v-col cols="12" sm="6">
          <TPDatePicker
            label="Expire Date"
            :key="requestData.datepickerKey || 0"
            v-model="requestData.expire_date"
            :rules="[validations.required()]"
            @input="dataTypeChange()"
          ></TPDatePicker>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>


<script>
import { GET_TAG_PRICING } from "@/store/pricing.module";
import TPDatePicker from "@/views/partials/form/DatePicker.vue";
import validations from "@/views/partials/form/Validations.vue";
import TPContactsAutocomplete from "@/views/tagProcessor/Input/ContactsAutocomplete.vue";

import moment from "moment";

export default {
  props: ["requestData"],
  components: { TPDatePicker,TPContactsAutocomplete },
  data() {
    return {
      validations: validations,
      selectChange: 0,
      pricingPublishedVia: [],
      types: [],
      tags: [],
      data: {},
      dType: 1,
      dateTypes: [
        { id: 1, name: "weeks" },
        { id: 2, name: "calendar" },
      ],
    };
  },
  mounted() {},
  methods: {
    getTag() {
      if (!this.requestData.type_id || !this.requestData.tag_id) {
        return;
      }
       this.$store
        .dispatch(GET_TAG_PRICING, this.requestData)
        .then((response) => {
            this.requestData.prepared = response.prepared;
              this.$emit('setParams');
          this.$emit("mappingDetails", response.details);
        });
    },
    getYearWeeks() {
      let weeks = [];

      let year = moment().format("Y");

      for (let index = 1; index <= 52; index++) {
        weeks.push({ id: year + "-" + index });
      }

      return weeks;
    },
    dataTypeChange() {
      this.requestData.datepickerKey++;
      this.getTag();
    },
     handleProcessing() {
      this.isProcessing = true;
    },
    handleProcessed() {
      this.isProcessing = false;
    },
    contactChange($event){
    },
  },
};
</script>

<style>
</style>