<template>
  <v-card-title>
    <v-text-field
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      @input="$emit('search', $event);"
    ></v-text-field>
    <v-menu
      bottom
      left
      max-height="50vh"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="mt-4 ml-8"
          color="indigo"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list class="mt-2 pb-0">
          <v-list-item :key="index" v-for="(filterData, key, index) in filters">
            <v-autocomplete
                dense
                chips
                small-chips
                multiple
                solo
                v-model="filtersValues[key]"
                :label="filterData.label"
                :items="filterData.items"
                @change="handleFiltersChange"
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="handleRemoveSelection(key, data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-list-item>
        </v-list>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="handleClearFilters"
          >
            Clear filters
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-card-title>
</template>

<script>

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      filtersValues: {}
    };
  },

  mounted() {
    if (this.filters) {
      this.initFiltersValues();
    }
  },

  watch: {
    filters() {
      this.initFiltersValues();
    }
  },

  methods: {
    initFiltersValues() {
      Object.keys(this.filters).forEach(key => {
        this.filtersValues[key] = [];
      });
    },

    handleFiltersChange() {
      let filters = [];
      for (const [key, filtersValues] of Object.entries(this.filtersValues)) {
        if (filtersValues.length) {
          filters[key] = [...filtersValues];
        }
      }

      this.$emit('filters-change', filters);
    },

    handleRemoveSelection(filterKey, item) {
      const index = this.filtersValues[filterKey].indexOf(item);
      if (index >= 0) {
        this.filtersValues[filterKey].splice(index, 1);

        this.handleFiltersChange();
      }
    },

    handleClearFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filtersValues[key].splice(0, this.filtersValues[key].length);
      });

      this.handleFiltersChange();
    }
  },
};
</script>

<style lang="sass" scoped>
::v-deep(.v-text-field__details)
    margin-bottom: 0 !important
</style>