<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" ref="dialog" max-width="400"  @keydown.esc="cancel" @keydown.enter="agree">
            <v-card>
                <v-card-title class="headline">Are you sure?</v-card-title>

                <v-card-text>
                    {{ message }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" text @click.native="cancel">
                        No
                    </v-btn>

                    <v-btn
                        ref="ConfirmBtn"
                        color="success"
                        text
                       @click.native="agree"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>


<script>
  export default {
    name: "ConfirmDlg",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null
      };
    },

    methods: {
      open(message) {
        this.dialog = true;
        this.message = message;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
         this.$refs.dialog.save();
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>